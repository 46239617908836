import React from 'react';
import Footer from './footer.js';
import Header from './header.js';
import Alert from "./alert.js";
import './pageStyles.css';
import '../pages/App.css';
import { AppContext } from "../libs/contextLib";
import dollar_background from '../images/100paper_bg2sl.jpg';
import { graphql,StaticQuery } from "gatsby";

import BackgroundImage from 'gatsby-background-image';


//const userHasAuthenticated = React.createContext('auth');
const withWrapper = (WrappedComponent,bgStyle,setKey) => {
  return class wrapper extends React.Component {
    constructor(props) {
      super(props);
    this.state={text:'',isAuthenticated:false};
//    let email=window.localStorage.getItem("email");
//    if(email) this.state.isAuthenticated=true;
//    this.state.email=email;
  }
  
  userHasAuthenticated=(state) => {
    this.setState({isAuthenticated:state});
  }
  setText=(txt) => {
      this.setState({text:txt});
  }

  render() {
      return (
        <AppContext.Provider value={[this.state.isAuthenticated, this.userHasAuthenticated]}>
          <div style={{minHeight: '100vh', position: 'relative', paddingBottom:'58px', width:'100%',overflow:'hidden'}}>
            <StaticQuery
            
              query ={graphql`
                query {
                  logoEl: file(relativePath: { eq: "logo.png" }) {
                    childImageSharp {
                      fixed(width: 287) {
                        ...GatsbyImageSharpFixed_withWebp_tracedSVG
                      }
                    }
                  }
                  bground: file(relativePath: { eq: "100paper_bg2sl.jpg" }) {
                    childImageSharp {
                      fixed(quality: 90, width: 160) {
                        ...GatsbyImageSharpFixed_withWebp
                      }
                    }
                  }
                }`
              }
              render={data => (
                <div>
                  <div className="underlay" style={{opacity:'.70',zIndex:"-10",position:'absolute',top:'0',bottom:'0',left:'0',right:'0'}}>
                    <BackgroundImage
                      Tag="section"
                      fixed={data.bground.childImageSharp.fixed}
                      backgroundColor={`#eee`}
                      style={{backgroundRepeat:'repeat', width: '100%', height:'100%',backgroundSize:'160px'}}
                    />
                  </div>                    
                    <div style={bgStyle}>
                      <Alert text={this.state.text} />
                      <Header {...this.props} setKey={setKey} setText={this.setText.bind(this)} data={data.logoEl.childImageSharp.fixed}/>
                      <div className='textOuter pS'>
                        <WrappedComponent setText={this.setText} {...this.props} />
                      </div>
                      <Footer setText={this.setText.bind(this)} pHeight='14px' />
                    </div>
                  
                </div>
              )}
            />

          </div>
        </AppContext.Provider>
      );
    }
  };
};

export default withWrapper;

