/******************************************************************
 * 
 * header.js
 * Right side menu having a login/logout, signup functionality
 * 
 * ***************************************************************/

import React, {useEffect,useRef} from 'react';
import { Link, graphql, StaticQuery } from "gatsby";
import Login from '../components/login.js';
import logo from '../images/logo_sm.png';
import Guilloche from "./guilloche.js";
import { renderToStaticMarkup } from 'react-dom/server';

import Img from "gatsby-image";



export default function Header(props) {
//  const logoElement= props.data.logoEl;
  const logoEl = useRef(null);
  useEffect(() => {
    console.log("Header props");
    console.log(props);
    window.addEventListener("scroll", shrinkLogo);
    return () => {
      window.removeEventListener("scroll", shrinkLogo);
    };
  });
  
  function shrinkLogo() {
    let off=window.pageYOffset;
    if(off>100) off=100;
    off=60-off/4;
    logoEl.current.style.height=off+'px';
    logoEl.current.style.width=off/0.2090592334494774+'px';
    
  }
  const svgString = encodeURIComponent(renderToStaticMarkup(<Guilloche />));
  const dataUri = `url("data:image/svg+xml,${svgString}")`;
  let topStyle= {
    paddingTop:'6px',
    fontSize:'13px',
    paddingRight:'min(25px,4.5vw)',
    paddingLeft:'min(25px,4.5vw)',
    paddingBottom: '6px',
    backgroundImage: dataUri,
    borderRadius: '0 0 0 4px',
    position:'relative'
  };

  return (
    <div>
        <div className="topMenu" style={{zIndex:"10",width:'100%',display: 'flex',position:'absolute'}}>
          <div style={{borderTop:'1px solid rgba(0,0,0,.96)',flexGrow:'1',position:'relative'}}>
            <div style={{ boxShadow: 'rgb(0, 0, 0) 0px -5px 2px',position: 'absolute',right: '-1px',height: '50%',top: '0px',left: '-1px',borderRight: '3px double rgba(0,0,0,.96)',borderTop: '3px double rgba(0,0,0,.96)',borderRadius: '0px 5px 0px 0px'}}></div>
            &nbsp;
          </div>

          <div style={topStyle}>
            <Login setZdown={() => {}} setZup={() => {}} {...props} setCompanies={() => {} } />
            <div style={{zIndex:'-1',position:'absolute', height: '60%',borderBottom: '1px solid rgba(0,0,0,.96)', borderLeft: '1px solid rgba(0,0,0,.96)', borderRadius: '0 0 0 5px',right: '0',bottom: '-2px',left: '-2px'}}></div>
          </div>
        </div>
        <div style={{width:'100%',position:'relative',textAlign:'left',padding:'30px 0px 30px 30px'}}>
            <Link to="/">
                <div ref={logoEl} style={{height:'60px', width: '287px',textDecorationColor: 'white',marginBottom:'10px'}}>
                  <Img style={{height: '100%',width: '100%' }} imgStyle={{height:'100%', width:'100%'}} fixed={props.data}  alt='Valtrace' />
                </div>
            </Link>
        </div>
    </div>
  );
}

